module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":970},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-84543261-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#c62828"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gobindathakur.com","short_name":"GT Blog","description":"My blogs on various topics.","start_url":"https://www.gobindathakur.com","background_color":"#e0f7fa","theme_color":"#263959","display":"minimal-ui","icons":[{"src":"./icons/favicon.ico","sizes":"64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"./icons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"./icons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"./icons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./icons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./icons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./icons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"ed343cfd-4711-4a17-99a0-00b842c4ab8f","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
